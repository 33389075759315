<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
        <path
            fill-rule="nonzero"
            d="M25.7570072 2.32628347 8.89874353 19.1670362l-6.63823981-6.6366479L0 14.774973l8.89874353 8.8987435L28 4.57086816z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
